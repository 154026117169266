import { render, staticRenderFns } from "./LogoName.vue?vue&type=template&id=7928ca8d&scoped=true&"
import script from "./LogoName.vue?vue&type=script&lang=js&"
export * from "./LogoName.vue?vue&type=script&lang=js&"
import style0 from "./LogoName.vue?vue&type=style&index=0&id=7928ca8d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7928ca8d",
  null
  
)

export default component.exports