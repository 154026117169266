import { render, staticRenderFns } from "./PhotoBlock.vue?vue&type=template&id=dd85542a&scoped=true&"
import script from "./PhotoBlock.vue?vue&type=script&lang=js&"
export * from "./PhotoBlock.vue?vue&type=script&lang=js&"
import style0 from "./PhotoBlock.vue?vue&type=style&index=0&id=dd85542a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd85542a",
  null
  
)

export default component.exports